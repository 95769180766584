import { useEffect } from "react"
import useMenu from "hooks/useMenu"

const IndexPage = () => {
  // Redirect to first workspace
  const { menu } = useMenu()
  useEffect(() => (window.location.href = menu[0].path), [menu])
  return null
}

export default IndexPage
